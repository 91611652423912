import { UtilsService } from "../../services/utils/utils.service";

export interface IAddressModel {
	addressId: number;
	uuid: string;
	addressType: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	latitude?: number;
	longitude?: number;
	isValid: boolean;
	isDefault: boolean;
	active: boolean;
}

export class AddressModel implements IAddressModel {
	addressId: number;
	uuid: string;
	addressType: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	latitude?: number;
	longitude?: number;
	isValid: boolean;
	isDefault: boolean;
	active: boolean;

	public static getNewAddressModel(isDefault?: boolean): IAddressModel {
		isDefault = !!isDefault;
		const newAddress: IAddressModel = new AddressModel();
		newAddress.uuid = UtilsService.newGuid();
		newAddress.addressType = "Home";
		newAddress.country = "US";
		newAddress.isDefault = isDefault;
		newAddress.active = true;

		return newAddress;
	}
}
