import { Injectable } from "@angular/core";

export enum ConcurrencyRecordTypes {
	Job,
	Estimate,
	Customer,
	Todo,
	Appointment,
	Equipment,
	Vendor,
	Client,
	Subcontactor
}

@Injectable()
export class ConcurrencyValidationService {
	constructor() { }

	async checkConcurrency(recordId: number, concurrencyType: ConcurrencyRecordTypes, version: number): Promise<string> {
		try {
			return new Promise<string>(async (resolve) => {
				const params = {
					recordId: recordId,
					concurrencyType: ConcurrencyRecordTypes[concurrencyType],
					version: version
				};

				resolve("Ok");
				//const response = await this.httpService.get("/concurrency/checkConcurrency", params);
				//resolve(response.message);
			});

		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}
