import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IItemModel, ItemModel } from "@models";

@Injectable()
export class ItemsService {
	constructor(private httpService: HttpService) { }

	async getItem(itemId: number): Promise<IItemModel> {
		return Promise.resolve(await this.httpService.get("/items/getItem", { itemId: itemId }));
	}

	async getItemPriceForCustomer(itemId: number, customerId: number): Promise<number> {
		const params = {
			itemId: itemId,
			customerId: customerId
		}
		return Promise.resolve(await this.httpService.get("/items/getItemPriceForCustomer", params));
	}

	async getItemPricesForCustomer(itemIds: number[], customerId: number): Promise<ItemModel[]> {
		const params = {
			itemIds: itemIds,
			customerId: customerId
		}
		return Promise.resolve(await this.httpService.get("/items/getItemPricesForCustomer", params));
	}

	async getItemsBySkusForCustomer(skus: string[], customerId: number): Promise<ItemModel[]> {
		const params = {
			skus: skus,
			customerId: customerId
		}
		return Promise.resolve(await this.httpService.get("/items/getItemsBySkusForCustomer", params));
	}

	async getItemPriceForSubmittingCompany(itemId: number, submittingCompanyId: number): Promise<number> {
		const params = {
			itemId: itemId,
			submittingCompanyId: submittingCompanyId
		}
		return Promise.resolve(await this.httpService.get("/items/getItemPriceForSubmittingCompany", params));
	}

	async getItemsBySkusForSubmittingCompany(skus: string[], submittingCompanyId: number): Promise<ItemModel[]> {
		const params = {
			skus: skus,
			submittingCompanyId: submittingCompanyId
		}
		return Promise.resolve(await this.httpService.get("/items/getItemsBySkusForSubmittingCompany", params));
	}

	async updateItem(itemModel: IItemModel, encodedImage: string): Promise<IItemModel> {
		let returnModel: IItemModel;

		if (itemModel.itemId === 0)
			returnModel = await this.httpService.post("/items/addItem", itemModel);
		else
			returnModel = await this.httpService.post("/items/updateItem", itemModel)

		if (encodedImage) {
			const response = await this.httpService.post(`/items/updateImage?itemId=${returnModel.itemId}`, { encodedImage: encodedImage });
			returnModel.imageUrl = response.imageUrl;
		}

		return Promise.resolve(returnModel);
	}

	async globalPriceIncrease(priceIncreasePercent: number): Promise<boolean> {
		return this.httpService.patch(`/items/globalPriceIncrease?priceIncreasePercent=${priceIncreasePercent}`)
    }
}
