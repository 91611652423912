import { UtilsService } from "@services";
import { IDropdownModel } from "../dropdown/dropdown.model";
import { OrderEngineSpecTypes } from "./order-engine-spec.model";

export interface IOrderEngineDefinitionConditionModel {
	uuid: string;
	specName: string;
	operator: string;
	specValue: string;

	specType: OrderEngineSpecTypes;
	specValues: IDropdownModel[];
}

export class OrderEngineDefinitionConditionModel implements IOrderEngineDefinitionConditionModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
		this.specType = OrderEngineSpecTypes.string;
	}

	uuid: string;
	specName: string;
	operator: string;
	specValue: string;

	specType: OrderEngineSpecTypes;
	specValues: IDropdownModel[];
}