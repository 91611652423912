import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { INotificationModel } from "./notifications.model";
//import { AuthService, UtilsService, NotificationsService } from "@services";
//import { NotificationsHubService } from "@signalr";
//import { SlickToastService } from "slick-components";
//import { INotificationModel } from "@models";

@Component({
	selector: "notifications",
	templateUrl: "notifications.component.html",
	styleUrls: ['notifications.component.css'],
	//providers: [NotificationsService, SlickToastService, NotificationsHubService, JobsService]
})

export class NotificationsComponent implements OnInit, OnDestroy {
	activeNotifications: INotificationModel[] = [];
	showingNotifications = false;

	//private fnDocumentClick = (e) => this.documentClick(e);

	constructor() { }

	async ngOnInit() {
		//this.activeNotifications = await this.notificationsService.getActiveNotifications();

		//if (this.authService.isAuthenticated === true) {
		//	this.notificationsHubService.hubConnection.on('AddNotification', (userId: string, notificationModel: INotificationModel) => this.addNotification(notificationModel));
		//}
	}

	ngOnDestroy() {
		//this.notificationsHubService.hubConnection.off('AddNotification');
	}

	//addNotification(notificationModel: INotificationModel) {
	//	this.activeNotifications.unshift(notificationModel);
	//	this.slickToastService.showInfo(notificationModel.message, 3000);
	//}

	//showNotifications() {
	//	if (this.showingNotifications === false) {
	//		this.showingNotifications = true;
	//		document.addEventListener("click", this.fnDocumentClick, true);
	//	}
	//	else {
	//		this.hideNotificationWindow();
	//	}

	//}

	//async clearAllNotifications() {
	//	let result = await this.notificationsService.clearAllNotifications();
	//	if (result === 'Ok') {
	//		this.activeNotifications = [];
	//		this.hideNotificationWindow();
	//	}
	//}

	//async clearNotification(notification: INotificationModel) {
	//	let result = await this.notificationsService.clearNotification(notification.notificationId);
	//	if (result === 'Ok') {
	//		this.activeNotifications = this.activeNotifications.filter(x => x.notificationId !== notification.notificationId);
	//		if (this.activeNotifications.length === 0)
	//			this.hideNotificationWindow();
	//	}
	//}

	//async editJob(jobId: number) {
	//	this.hideNotificationWindow();

	//	this.jobModel = await this.jobsService.getJob(jobId);

	//	await this.jobEditDialogRef.showDialog();
	//}

	//onJobSave() {
	//	this.slickToastService.showSuccess("Job Updated");
	//}

	//private hideNotificationWindow() {
	//	document.removeEventListener("click", this.fnDocumentClick, true);
	//	this.showingNotifications = false;
	//}

	//documentClick(e) {
	//	if (UtilsService.checkParentClassExists(e.target, "notification-details"))
	//		return;

	//	this.hideNotificationWindow();
	//}
}
