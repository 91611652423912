import { UtilsService } from "../../services/utils/utils.service";
import { ICustomerModel } from "../customers/customer.model";
import { ILineItem } from "../line-items/line-items.model";
import { IOrderSpecs, OrderSpecs } from "../order-specs/order-specs.model";
import { ICompanyInfoModel } from "../companies/company-info.model";
import { IDocumentModel } from "../documents/document.model";

export interface IOrderModel {
	orderId: number;
	uuid: string;
	version: number;
	submittingCompany: ICompanyInfoModel;
	editing: boolean;
	documentFolderId?: number;
	warrantyRegistrationId?: number;
	orderTypeId: number;
	orderTypeDescription: string;
	orderStatusId: number;
	orderStatusDescription: string;
	orderDate?: Date;
	customer: ICustomerModel;
	builder: ICustomerModel;
	orderNumber: string;
	salesOrderNumber: string;
	carrier: string;
	poNumber: string;
	shipTo: string;
	shipToDisplayName: string;
	shipToAddress1: string;
	shipToAddress2: string;
	shipToCity: string;
	shipToState: string;
	shipToPostalCode: string;
	shipToCountry: string;
	shipWithPool?: boolean;
	requestedDeliveryDate?: Date;
	proNumber: string;
	orderNotes: string;
	lineItems: ILineItem[];
	orderSpecs: IOrderSpecs;
	documents: IDocumentModel[];
	photos: IDocumentModel[];
	active: boolean;
}

export class OrderModel implements IOrderModel {
	orderId: number;
	uuid: string;
	version: number;
	submittingCompany: ICompanyInfoModel;
	editing: boolean;
	documentFolderId?: number;
	warrantyRegistrationId?: number;
	orderTypeId: number;
	orderTypeDescription: string;
	orderStatusId: number;
	orderStatusDescription: string;
	orderDate?: Date;
	customer: ICustomerModel;
	builder: ICustomerModel;
	orderNumber: string;
	salesOrderNumber: string;
	carrier: string;
	poNumber: string;
	shipTo: string;
	shipToDisplayName: string;
	shipToAddress1: string;
	shipToAddress2: string;
	shipToCity: string;
	shipToState: string;
	shipToPostalCode: string;
	shipToCountry: string;
	proNumber: string;
	orderNotes: string;
	lineItems: ILineItem[];
	orderSpecs: IOrderSpecs;
	documents: IDocumentModel[];
	photos: IDocumentModel[];
	active: boolean;

	public static getNewOrderModel(orderNumber: string) {
		const orderModel = new OrderModel();

		orderModel.orderId = 0;
		orderModel.uuid = UtilsService.newGuid();
		orderModel.version = 0;
		orderModel.documentFolderId = null;
		orderModel.orderNumber = orderNumber;
		orderModel.orderTypeId = 2;
		orderModel.orderTypeDescription = "Replacement Cover";
		orderModel.orderStatusId = 1;
		orderModel.orderStatusDescription = "Open";
		orderModel.carrier = "UPS Ground";
		orderModel.active = true;

		orderModel.orderSpecs = new OrderSpecs();
		orderModel.orderSpecs.orderType = "Replacement Cover";

		orderModel.lineItems = [];

		orderModel.documents = [];
		orderModel.photos = [];

		return orderModel;
	}
}