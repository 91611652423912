import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IItemModel, ItemModel, IKanbanCardModel, IKanbanCardPrintModel } from "@models";

@Injectable()
export class KanbanService {
	constructor(private httpService: HttpService) { }

	async getKanbanCard(itemId: number): Promise<IKanbanCardModel> {
		return Promise.resolve(await this.httpService.get("/kanban/getKanbanCard", { itemId: itemId }));
	}

	async updateKanbanCard(kanbanCardModel: IKanbanCardModel): Promise<IKanbanCardModel> {
		return Promise.resolve(await this.httpService.post("/kanban/updateKanbanCard", kanbanCardModel));
	}

	async generateKanbanCardPdf(kanbanCardPrintModel: IKanbanCardPrintModel): Promise<any> {
		return Promise.resolve(await this.httpService.post("/kanban/generateKanbanCardPDF", kanbanCardPrintModel));
	}
}
