import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";

@Injectable()
export class HttpService {
	static errorDialogComponentCallback: any;

	constructor(private http: HttpClient) { }

	async get(url: string, params?: any): Promise<any> {
			if (!url) {
				console.error("Url is blank");
				debugger;
				return Promise.resolve(null);
			}

			let responseObj = await this.http.get(url, { params: params }).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return Promise.resolve(responseObj);
	}

	async getPdf(url: string): Promise<Blob> {
			let responseObj = await this.http.get(url, { responseType: "blob" }).toPromise();

			return Promise.resolve(responseObj);
	}

	async delete(url: string, params?: any): Promise<any> {
			let responseObj = await this.http.delete(url, { params: params }).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return Promise.resolve(responseObj);
	}

	async post(url: string, body: any): Promise<any> {
			let responseObj = await this.http.post(url, body).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return Promise.resolve(responseObj);
	}

	async postMultipart(url: string, formData: FormData): Promise<any> {
		let responseObj = await this.http.post(url, formData,
			{
				headers: { 'Content-Type': "multipart/form-data" }
			}).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async put(url: string, body: any): Promise<any> {
			let responseObj = await this.http.put(url, body).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return Promise.resolve(responseObj);
	}

	async patch(url: string, body: any = null): Promise<any> {
			let responseObj = await this.http.patch(url, body).toPromise();

			responseObj = UtilsService.dateSanitize(responseObj);

			return Promise.resolve(responseObj);
	}

}
