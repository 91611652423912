import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HeaderBarComponent } from "./header/header-bar.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { SlickPopoverModule } from "slick-components";

@NgModule({
	imports: [CommonModule, RouterModule, SlickPopoverModule],
	declarations: [HeaderBarComponent, NotificationsComponent, SideMenuComponent],
	exports: [HeaderBarComponent, SideMenuComponent]
})
export class LayoutModule {
}
