import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { IDropdownModel, DropdownModel } from "../models/dropdown/dropdown.model";
import { IFabricColor, FabricColor } from "../models/order-specs/fabric-color.model";
import { IOrderSpecs, IOrderModel } from "@models";

@Injectable()
export class OrderSpecsService {
	constructor() { }

	getShipTo(): IDropdownModel[] {
		return [new DropdownModel('Customer', 'Customer'),
		new DropdownModel('Builder', 'Builder'),
		new DropdownModel('Dealer', 'Dealer')];
	}

	getCarriers(): IDropdownModel[] {
		return [
			new DropdownModel('Will Call', 'Will Call'),
			new DropdownModel('Standard Shipping', 'Standard Shipping'),
			new DropdownModel('Expedited', 'Expedited')];

	}


	getUnitLocations(): IDropdownModel[] {
		return [new DropdownModel("Underguide", "Underguide"),
		new DropdownModel("Recessed Topguide", "Recessed Topguide"),
		new DropdownModel("Deck Mount", "Deck Mount")]
	}

	getMotorTypes(): IDropdownModel[] {
		return [new DropdownModel("Standard", "Standard"),
		new DropdownModel("Heavy Duty (30rpm)", "Heavy Duty (30rpm)")]
	}

	getMotorSides(): IDropdownModel[] {
		return [new DropdownModel("Left", "Left"),
		new DropdownModel("Right", "Right"),
		new DropdownModel("TBD", "TBD")]
	}

	getPoolTypes(): IDropdownModel[] {
		return [new DropdownModel("Fiberglass", "Fiberglass"),
		new DropdownModel("Gunite", "Gunite"),
		new DropdownModel("Vinyl Liner", "Vinyl Liner")]
	}

	getDeckTypes(): IDropdownModel[] {
		return [new DropdownModel("Concrete", "Concrete"),
		new DropdownModel("Pavers", "Pavers"),
		new DropdownModel("Wood", "Wood")]
	}

	getGuideTypes(isReplacementCover: boolean, unitLocation: string): IDropdownModel[] {
		let guideTypes: IDropdownModel[] = [];

		if (isReplacementCover)
			guideTypes = [new DropdownModel("Encapsulated Underguide", "Encapsulated Underguide"),
			new DropdownModel("Topguide", "Topguide"),
			new DropdownModel("Screw-On Underguide", "Screw-On Underguide")];
		else {
			switch (unitLocation) {
				case "Underguide":
					guideTypes = [new DropdownModel("Encapsulated Underguide", "Encapsulated Underguide"),
					new DropdownModel("Screw-On Underguide", "Screw-On Underguide")];
					break;

				case "Recessed Topguide":
				case "Deck Mount":
					guideTypes = [new DropdownModel("Topguide", "Topguide")];
					break;
			}
		}

		return guideTypes;
	}

	getHousingMountingKits(): IDropdownModel[] {
		return [new DropdownModel("None", "None"),
		new DropdownModel("Vinyl Liner", "Vinyl Liner"),
		new DropdownModel("Fiberglass", "Fiberglass"),
		new DropdownModel("Free Standing", "Free Standing")]
	}

	getEncapsulationTypes(poolType: string, guideType: string): IDropdownModel[] {
		if (guideType === "Encapsulated Underguide") {
			switch (poolType) {
				case "Gunite":
					return [
						new DropdownModel("Gunite", "Gunite"),
						new DropdownModel("Pool-in-Pool", "Pool-in-Pool")
					]
					break;

				case "Fiberglass":
					return [
						new DropdownModel("Fiberglass", "Fiberglass"),
						new DropdownModel("Pool-in-Pool", "Pool-in-Pool")
					]
					break;

				case "Vinyl Liner":
					return [
						new DropdownModel("Vinyl Liner", "Vinyl Liner"),
						new DropdownModel("Pool-in-Pool", "Pool-in-Pool")
					]
					break;
			}
		}
		else {
			return [
				new DropdownModel("Vinyl Liner", "Vinyl Liner"),
				new DropdownModel("Fiberglass", "Fiberglass"),
				new DropdownModel("Gunite", "Gunite"),
				new DropdownModel("Pool-in-Pool", "Pool-in-Pool")
			]
		}
	}

	getVinylLinerRadiusCornerTypes(): IDropdownModel[] {
		return [new DropdownModel("90 Degree (No Corner)", "90 Degree (No Corner)"),
		new DropdownModel("6in Radius", "6in Radius"),
		new DropdownModel("2ft Radius", "2ft Radius")]
	}

	getClipOnCopingTypes(): IDropdownModel[] {
		return [new DropdownModel("Rounded", "Rounded"),
		new DropdownModel("Bullnose", "Bullnose")]
	}

	getLidTypes(unitLocation: string, deckType: string): IDropdownModel[] {
		if (unitLocation === 'Recessed Topguide') {
			return [new DropdownModel("None", "None"),
			new DropdownModel("Hinged Flat Lid", "Hinged Flat Lid"),
			new DropdownModel("Basic Aluminum Lid", "Basic Aluminum Lid")]

		}
		else {
			if (deckType !== 'Concrete') {
				return [new DropdownModel("None", "None"),
				new DropdownModel("Drop-in Flat Lid", "Drop-in Flat Lid"),
				new DropdownModel("Hinged Flat Lid", "Hinged Flat Lid"),
				new DropdownModel("Basic Aluminum Lid", "Basic Aluminum Lid"),
				new DropdownModel("Stone Lid", "Stone Lid")]
			}
			else {
				return [new DropdownModel("None", "None"),
				new DropdownModel("Flush Lid", "Flush Lid"),
				new DropdownModel("Drop-in Flat Lid", "Drop-in Flat Lid"),
				new DropdownModel("Hinged Flat Lid", "Hinged Flat Lid"),
				new DropdownModel("Basic Aluminum Lid", "Basic Aluminum Lid"),
				new DropdownModel("Stone Lid", "Stone Lid")]
			}
		}
	}

	getWalkOnLidBracketAnchoring(): IDropdownModel[] {
		return [new DropdownModel("None", "None"),
		new DropdownModel("Box Perimeter Hook", "Box Perimeter Hook"),
		new DropdownModel("Stainless Wedge Anchor", "Stainless Wedge Anchor")]
	}

	getWalkOnLidBracketExtensions(): IDropdownModel[] {
		return [new DropdownModel("None", "None"),
		new DropdownModel("15-17in", "15-17in"),
		new DropdownModel("17-19in", "17-19in"),
		new DropdownModel("19-21in", "19-21in"),
		new DropdownModel("21-23in", "21-23in"),
		new DropdownModel("23-25in Dbl Thick", "17-19in Dbl Thick")]
	}

	getOEMs(guideType: string): IDropdownModel[] {
		let oems: IDropdownModel[] = [new DropdownModel("Integra", "Integra"),
		new DropdownModel("Coverstar", "Coverstar"),
		new DropdownModel("Coverpools", "Coverpools"),
		new DropdownModel("T3 Universal track w/Wheels", "T3 Universal track w/Wheels"),
		new DropdownModel("Aquamatic", "Aquamatic"),
		new DropdownModel("PCS", "PCS"),
		new DropdownModel("APC", "APC")];

		if (guideType === 'Underguide') {
			oems = [new DropdownModel("Integra", "Integra"),
			new DropdownModel("Coverstar", "Coverstar"),
			new DropdownModel("Coverpools", "Coverpools"),
			new DropdownModel("Coverpools T4 Dual Race", "Coverpools T4 Dual Race"),
			new DropdownModel("Coverpools T3 Adj Glider Undertrack, Snap top or Universal Glider", "Coverpools T3 Adj Glider Undertrack, Snap top or Universal Glider"),
			new DropdownModel("Coverpools T3 Non-ADJ Glider Undertrack", "Coverpools T3 Non-ADJ Glider Undertrack"),
			new DropdownModel("Coverpools T4 Under track California corner", "Coverpools T4 Under track California corner"),
			new DropdownModel("Coverpools T3 Under track California corner", "Coverpools T3 Under track California corner"),
			new DropdownModel("Aquamatic", "Aquamatic"),
			new DropdownModel("PCS", "PCS"),
			new DropdownModel("APC", "APC")]
		}
		else if (guideType === 'Topguide') {
			oems = [new DropdownModel("Integra", "Integra"),
			new DropdownModel("Coverstar", "Coverstar"),
			new DropdownModel("Coverpools", "Coverpools"),
			new DropdownModel("Aquamatic", "Aquamatic"),
			new DropdownModel("PCS", "PCS"),
			new DropdownModel("APC", "APC")];
		}

		return oems;
	}

	getFabricColors(): IFabricColor[] {
		return [new FabricColor("Royal Blue", "#1d71c7"),
		new FabricColor("Navy", "#083c8f"),
		new FabricColor("Charcoal", "#81817f"),
		new FabricColor("Gray", "#c4c3bf"),
		new FabricColor("Tan", "#dfd2b2"),
		new FabricColor("Mocha", "#947963")]

	}

	getWebbingTypes(isReplacementCover: boolean, oem: string): IDropdownModel[] {
		let webbingTypes: IDropdownModel[] = [];

		if (isReplacementCover) {
			webbingTypes = [new DropdownModel("Color Matched Heat Sealed", "Color Matched Heat Sealed"),
			new DropdownModel("Sewn Dacron White", "Sewn Dacron White"),
			new DropdownModel("Color Matched Dacron", "Color Matched Dacron")];

			switch (oem) {
				case 'Coverpools T3 Adj Glider Undertrack, Snap top or Universal Glider':
				case 'Coverpools T3 Universal track w/Wheels':
				case 'Coverpools T3 Non-ADJ Glider Undertrack':
				case 'Coverpools T3 Under track California corner':
					webbingTypes = [new DropdownModel("Color Matched Dacron", "Color Matched Dacron")];
					break;

				case 'Coverpools T4 Dual Race':
				case 'Coverpools T4 Under track California corner':
					webbingTypes = [new DropdownModel("Color Matched Heat Sealed", "Color Matched Heat Sealed")];
					break;
			}
		}
		else {
			webbingTypes = [new DropdownModel("Color Matched Heat Sealed", "Color Matched Heat Sealed")];
		}


		return webbingTypes;
	}


	getSliderTypes(isReplacementCover: boolean, ropeAttachment: string): IDropdownModel[] {
		let sliderTypes: IDropdownModel[] = [];

		if (isReplacementCover) {
			sliderTypes = [new DropdownModel("No Slider", "No Slider"),
			new DropdownModel("IPC Slider", "IPC Slider"),
			new DropdownModel("IPC Slider (PCS Modified)", "IPC Slider (PCS Modified)"),
			new DropdownModel("CoverPools Replacement Slider Assembly", "CoverPools Replacement Slider Assembly"),
			new DropdownModel("Aquamatic Replacement Slider Assembly", "Aquamatic Replacement Slider Assembly"),
			new DropdownModel("Replacement Slider 403 Assembly", "Replacement Slider 403 Assembly"),
			new DropdownModel("Replacement Slider 801 Assembly", "Replacement Slider 801 Assembly")]
		}
		else {
			if (ropeAttachment == 'Detachable w/IPC tab' || ropeAttachment == 'Continuous w/IPC tab') {
				sliderTypes = [
					new DropdownModel('IPC Slider', 'IPC Slider'),
				]
			}
			else if (ropeAttachment == 'Detachable w/3 hole tab' || ropeAttachment == 'Continuous w/3 hole tab') {
				sliderTypes = [
					new DropdownModel('Replacement Slider 403 Assembly', 'Replacement Slider 403 Assembly'),
				]
			}
			else {
				sliderTypes = [
					new DropdownModel('IPC 403 Slider', 'IPC 403 Slider'),
					new DropdownModel('IPC Slider', 'IPC Slider'),
				]
			}
		}

		return sliderTypes;
	}

	getLeadingEdgeBarTypes(isReplacementCover: boolean): IDropdownModel[] {
		let leadingEdgeBarTypes: IDropdownModel[] = [];

		if (isReplacementCover) {
			leadingEdgeBarTypes = [
				new DropdownModel("1 Inch Flat", "1 Inch Flat"),
				new DropdownModel("1 ¼ Inch Flat", "1 ¼ Inch Flat"),
				new DropdownModel("2 Inch Round", "2 Inch Round"),
				new DropdownModel("2 ½ Inch Rectangle", "2 ½ Inch Rectangle"),
				new DropdownModel("3 Inch Round", "3 Inch Round"),
				new DropdownModel("4 Inch Round", "4 Inch Round")]
		}
		else {
			leadingEdgeBarTypes = [new DropdownModel("2 ½ Rectangle", "2 ½ Rectangle"),
			new DropdownModel("Spliced 2 ½ Rectangle", "Spliced 2 ½ Rectangle")]
		}

		return leadingEdgeBarTypes;
	}

	getRollupTubeTypes(guideSpaceFeet: number, guideSpaceInches): IDropdownModel[] {
		const totalGuideSpaceInches = ((guideSpaceFeet ?? 0) * 12) + (guideSpaceInches ?? 0);
		if (totalGuideSpaceInches >= 242) {
			return [
				new DropdownModel("6in Extruded", "6in Extruded"),
				new DropdownModel("Spliced 6in Extruded", "Spliced 6in Extruded")]
		}
		else {
			return [
				new DropdownModel("5in Extruded", "5in Extruded"),
				new DropdownModel("6in Extruded", "6in Extruded"),
				new DropdownModel("Spliced 5in Extruded", "Spliced 5in Extruded"),
				new DropdownModel("Spliced 6in Extruded", "Spliced 6in Extruded")]

		}
	}

	// 6/24/20 - Per Toby's request, changed rope attachments to the following (see email)
	// 11/12/20 - Rope attachments are only IPC if a system
	// 9/13/22 - Per Clint, remove all Detachable ropes from the system
	// 10/21/22 - Per Earnest, add detachable ropes back in
	// 12/20/23 - Per Bridger, add in TopGuide Slider for topguide systems
	getRopeAttachmentTypes(isSystem: boolean, guideType: string, oem: string, systemSqFt: number): IDropdownModel[] {
		if (isSystem === true) {
			if (guideType === 'Topguide' && (oem === 'Integra' || oem === 'Coverstar')) {
				return [new DropdownModel("Continuous w/3 hole tab", "Continuous w/3 hole tab")]
			}
			else {				
				if (systemSqFt > 910) {
					return [new DropdownModel("Continuous w/3 hole tab", "Continuous w/3 hole tab")]
				}
				else {
					return [new DropdownModel("Detachable w/IPC tab", "Detachable w/IPC tab"),
					new DropdownModel("Continuous w/IPC tab", "Continuous w/IPC tab"),
					new DropdownModel("Detachable w/3 hole tab", "Detachable w/3 hole tab"),
					new DropdownModel("Continuous w/3 hole tab", "Continuous w/3 hole tab")]
				}
			}
		}
		else {
			if (guideType === 'Topguide' && (oem === 'Integra' || oem === 'Coverstar')) {
				return [new DropdownModel("Continuous w/3 hole tab", "Continuous w/3 hole tab")]
			}
			else {
				return [new DropdownModel("No Rope", "No Rope"),
				new DropdownModel("Detachable w/IPC tab", "Detachable w/IPC tab"),
				new DropdownModel("Continuous w/IPC tab", "Continuous w/IPC tab"),
				new DropdownModel("Continuous", "Continuous"),
				new DropdownModel("Detachable w/3 hole tab", "Detachable w/3 hole tab"),
				new DropdownModel("Continuous w/3 hole tab", "Continuous w/3 hole tab")]
			}
		}
	}

	getCoverOptions(): IDropdownModel[] {
		return [new DropdownModel("None", "None"),
		new DropdownModel("Std Mesh Panel (leading edge)", "Std Mesh Panel (leading edge)"),
		new DropdownModel("Custom Mesh Panel", "Custom Mesh Panel"),
		new DropdownModel(`1" Set back corner`, `1" Set back corner`),
		new DropdownModel(`2" Setback Corners`, `2" Setback Corners`),
		new DropdownModel(`4" Setback Corners`, `4" Setback Corners`),
		new DropdownModel(`Winged Corner`, `Winged Corner`)]

	}

	recalcDefaults(orderModel: IOrderModel): IOrderModel {
		const isSystem = orderModel.orderSpecs.orderType === 'System';
		// Only if it's a system
		if (isSystem) {
			if (orderModel.orderSpecs.unitLocation == "Recessed Topguide" || orderModel.orderSpecs.unitLocation == "Deck Mount") {
				orderModel.orderSpecs.sliderType = 'None';
			}
			else {
				if (orderModel.orderSpecs.ropeAttachment == 'Detachable w/IPC tab' || orderModel.orderSpecs.ropeAttachment == 'Continuous w/IPC tab')
					orderModel.orderSpecs.sliderType = 'IPC Slider';
				else if (orderModel.orderSpecs.ropeAttachment == 'Detachable w/3 hole tab' || orderModel.orderSpecs.ropeAttachment == 'Continuous w/3 hole tab')
					orderModel.orderSpecs.sliderType = 'Replacement Slider 403 Assembly'
			}
		}

		return orderModel;
	}

	calcSystemSize(orderSpecs: IOrderSpecs, addSlackAndRollerWrap: boolean = false): number {
		const slack = orderSpecs.slack || 0;
		const rollerWrap = orderSpecs.rollerWrap || 0;

		const gsFeet = orderSpecs.guideSpaceFeet || 0;
		const gsInches = (orderSpecs.guideSpaceInches || 0) + ((addSlackAndRollerWrap === true) ? slack : 0);

		const glFeet = (orderSpecs.guideLengthFeet || 0) + ((addSlackAndRollerWrap === true) ? rollerWrap : 0);
		const glInches = (orderSpecs.guideLengthInches || 0);

		const gsTotalInches = (gsFeet * 12) + gsInches;
		const glTotalInches = (glFeet * 12) + glInches;

		const size = (gsTotalInches * glTotalInches) / 144;
		return UtilsService.round(size + .49, 0);
	}

	recalcDefaultSizes(orderModel: IOrderModel): IOrderModel {
		orderModel.orderSpecs.systemSqft = this.calcSystemSize(orderModel.orderSpecs);
		orderModel.orderSpecs.totalSqft = this.calcSystemSize(orderModel.orderSpecs, true);

		if (orderModel.orderSpecs.slackOverride === false) {
			if (orderModel.orderSpecs.guideType === "Topguide") {
				orderModel.orderSpecs.slack = 7;
			}
			else {
				const gsFeet = orderModel.orderSpecs.guideSpaceFeet || 0;
				if (gsFeet <= 10)
					orderModel.orderSpecs.slack = 4;
				else if (gsFeet <= 20)
					orderModel.orderSpecs.slack = 5;
				else
					orderModel.orderSpecs.slack = 6;
			}
		}

		if (orderModel.orderSpecs.rollerWrapOverride === false) {
			orderModel.orderSpecs.rollerWrap = 3;
		}

		return orderModel;
	}
}
