import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IDocumentFolderModel, IDocumentModel } from '@models';
import { GlobalsService } from ".";

@Injectable()
export class DocumentsService {
	constructor(private httpService: HttpService) { }

	async getNewDocumentFolder(path: string): Promise<IDocumentFolderModel> {
		return this.httpService.get("/documents/getNewDocumentFolder", { path: path });
	}

	async addDocuments(documentFolderId: number, files: IDocumentModel[]): Promise<IDocumentModel[]> {
		const formData = new FormData();
		files.forEach(file => {
			formData.append("file", file.file, file.file.name);
		});

		return this.httpService.postMultipart(`documents/addDocuments?documentFolderId=${documentFolderId}`, formData);

	}

	async getDocumentUrl(companyId: number, documentId: number): Promise<any> {
		const params = {
			companyId,
			documentId
		}
		return this.httpService.get("/documents/getDocumentUrl", params);
	}
}
