import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IUserPermissionModel } from "@models";

@Injectable()
export class PermissionsService {
	constructor(private httpService: HttpService) { }

	async getPermissionsForUserRole(userRoleId: number): Promise<IUserPermissionModel[]> {
		return Promise.resolve(await this.httpService.get("/permissions/getPermissionsForUserRole", { userRoleId: userRoleId }));
	}
}