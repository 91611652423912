import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IOrderModel, IOrderGridModel, IDocumentModel, IContactModel, ICustomShippingAddressModel, IDocumentFolderModel } from "@models";
import { GlobalsService } from "./utils/globals.service";
import { FunctionLockService } from "./utils/function-lock.service";

@Injectable()
export class OrdersService {
	constructor(private httpService: HttpService,
		private functionLockService: FunctionLockService) { }

	async getNextOrderNumber(): Promise<string> {
		return Promise.resolve(await this.httpService.get("/orders/getNextOrderNumber"));
	}

	async getNewDocumentFolder(orderNumber: string): Promise<IDocumentFolderModel> {
		return Promise.resolve(await this.httpService.get("/orders/getNewDocumentFolder", { orderNumber: orderNumber }));
	}

	async getOrder(orderId: number): Promise<IOrderModel> {
		return Promise.resolve(await this.httpService.get("/orders/getOrder", { orderId: orderId }));
	}

	async getOrderForGrid(orderId: number): Promise<IOrderGridModel> {
		return Promise.resolve(await this.httpService.get("/orders/getOrderForGrid", { orderId: orderId }));
	}

	async getOrdersForInfiniteScroll(pageCount: number, searchString: string) {
		const params = {
			pageCount,
			searchString
		}

		return Promise.resolve(await this.httpService.get("/orders/getOrdersForInfiniteScroll", params));
	}

	async getOrderQCPhotos(orderId: number): Promise<IDocumentModel[]> {
		return Promise.resolve(await this.httpService.get("/orders/getOrderQCPhotos", { orderId: orderId }));
	}

	async updateOrder(orderModel: IOrderModel): Promise<IOrderModel> {
		if (orderModel.orderId === 0)
			return await this.httpService.post("/orders/addOrder", orderModel);
		else
			return await this.httpService.post("/orders/updateOrder", orderModel)
	}

	async approveOrder(orderModel: IOrderModel): Promise<IOrderModel> {
		return await this.httpService.post("/orders/approveOrder", orderModel)
	}

	async paymentReceived(orderModel: IOrderModel): Promise<IOrderModel> {
		return await this.httpService.post("/orders/paymentReceived", orderModel)
	}

	async backToPending(orderModel: IOrderModel): Promise<IOrderModel> {
		return await this.httpService.post("/orders/backToPending", orderModel)
	}

    async submitOrder(orderModel: IOrderModel): Promise<IOrderModel> {
        return await this.httpService.post("/orders/submitOrder", orderModel)
    }

	async deleteOrder(orderId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.delete(`/orders/deleteOrder?orderId=${orderId}`));
	}

	async generateAllPdf(orderModel: IOrderModel): Promise<any> {
		return Promise.resolve(await this.httpService.post("/orders/generateAllPDF", orderModel));
	}

	async generateOrderPdf(orderModel: IOrderModel): Promise<any> {
		return Promise.resolve(await this.httpService.post("/orders/generateOrderPDF", orderModel));
	}

	async refreshSystemItems(orderModel: IOrderModel): Promise<IOrderModel> {
		await this.functionLockService.lock("ORDER_SPECS_REFRESHSYSTEMLINEITEMS");
		try {
			// Only refresh on non-submitted orders
			if (orderModel.orderStatusId > 1 && !orderModel.editing)
				return orderModel;

			const systemLineItems = await this.httpService.post("/orders/getSystemLineItems", orderModel);
			orderModel.lineItems = orderModel.lineItems.filter(x => x.isSystemLineItem === false);
			orderModel.lineItems = [...systemLineItems, ...orderModel.lineItems];

			return orderModel;
		}
		finally {
			this.functionLockService.release("ORDER_SPECS_REFRESHSYSTEMLINEITEMS");
		}
	}

	refreshShipping(orderModel: IOrderModel, customShippingAddresses: ICustomShippingAddressModel[]): IOrderModel {
		orderModel.shipToAddress1 = null;
		orderModel.shipToAddress2 = null;
		orderModel.shipToCity = null;
		orderModel.shipToState = null;
		orderModel.shipToPostalCode = null;
		orderModel.shipToCountry = null;

		if (!orderModel.shipTo)
			return orderModel;

		if (orderModel.shipTo === "Customer" && orderModel.customer)
			this.setShipTo(orderModel.customer.contact, orderModel);
		else if (orderModel.shipTo === "Builder" && orderModel.builder)
			this.setShipTo(orderModel.builder.contact, orderModel);
		else if (orderModel.shipTo === "Dealer")
			this.setShipTo(GlobalsService.companyInfo.shippingContact, orderModel);
		else {
			const customShippingAddress = customShippingAddresses.find(x => x.customShippingAddressId.toString() === orderModel.shipTo);

			if (!customShippingAddress) {
				orderModel.shipToDisplayName = "** Not Found **";
				return orderModel;
			}

			orderModel.shipToDisplayName = null;
			orderModel.shipToAddress1 = customShippingAddress.address1;
			orderModel.shipToAddress2 = customShippingAddress.address2;
			orderModel.shipToCity = customShippingAddress.city;
			orderModel.shipToState = customShippingAddress.state;
			orderModel.shipToPostalCode = customShippingAddress.postalCode;
			orderModel.shipToCountry = customShippingAddress.country;

		}
		return orderModel;
	}

	private setShipTo(contact: IContactModel, orderModel: IOrderModel) {
		orderModel.shipToDisplayName = contact.displayName;
		orderModel.shipToAddress1 = contact.defaultAddress.address1;
		orderModel.shipToAddress2 = contact.defaultAddress.address2;
		orderModel.shipToCity = contact.defaultAddress.city;
		orderModel.shipToState = contact.defaultAddress.state;
		orderModel.shipToPostalCode = contact.defaultAddress.postalCode;
		orderModel.shipToCountry = contact.defaultAddress.country;
	}

}
