import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IHelpPageModel } from "@models";

@Injectable()
export class HelpPagesService {
	constructor(private httpService: HttpService) { }

	async getHelpPage(helpPageId: string): Promise<IHelpPageModel> {
		return this.httpService.get("/helpPages/getHelpPage", { helpPageId: helpPageId });
	}

	async getAllHelpPages(): Promise<IHelpPageModel[]> {
		return this.httpService.get("/helpPages/getAllHelpPages");
	}

	async updateHelpPage(helpPageModel: IHelpPageModel): Promise<IHelpPageModel> {
		return this.httpService.post("/helpPages/updateHelpPage", helpPageModel);
	}
}
