export interface IKanbanCardPrintModel {
	sku: string;
	description: string;
	supplier: string;
	kanbanSize: string;
	building: string;
	row: string;
	rack: string;
	shelf: string;
	bin: string;
	minStock: string;
	leadTime: string;
	orderQty: string;
	dueDate: Date;
	imageUrl: string;
	is3x5: boolean;
}

export class KanbanCardPrintModel implements IKanbanCardPrintModel {
	sku: string;
	description: string;
	supplier: string;
	kanbanSize: string;
	building: string;
	row: string;
	rack: string;
	shelf: string;
	bin: string;
	minStock: string;
	leadTime: string;
	orderQty: string;
	dueDate: Date;
	imageUrl: string;
	is3x5: boolean;
}