import { UtilsService } from "../../services/utils/utils.service";

export interface IPhoneNumberModel {
	phoneNumberId: number;
	uuid: string;
	phoneNumberType: string;
	phoneNumber: string;
	isDefault: boolean;
	active: boolean;
}

export class PhoneNumberModel implements IPhoneNumberModel {
	phoneNumberId: number;
	uuid: string;
	phoneNumberType: string;
	phoneNumber: string;
	isDefault: boolean;
	active: boolean;

	public static getNewPhoneNumberModel(isDefault?: boolean, phoneNumberType?: string): IPhoneNumberModel {
		isDefault = !!isDefault;
		const newPhoneNumber: IPhoneNumberModel = new PhoneNumberModel();
		newPhoneNumber.uuid = UtilsService.newGuid();
		newPhoneNumber.phoneNumberType = phoneNumberType || "Home";
		newPhoneNumber.isDefault = isDefault;
		newPhoneNumber.active = true;

		return newPhoneNumber;
	}

}
