import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IShippingOrderModel, ICustomShippingAddressModel } from "@models";

@Injectable()
export class ShippingService {
	constructor(private httpService: HttpService) { }

	async getAllOrdersForShipping(productionFacility: string): Promise<IShippingOrderModel[]> {
		return await this.httpService.get("/shipping/getAllOrdersForShipping", { productionFacility: productionFacility});
	}

	async getOrderForMasterProduction(orderId: number): Promise<IShippingOrderModel> {
		const params = {
			orderId: orderId
		}

		return await this.httpService.get("/shipping/getOrderForShipping", params);
	}

	async moveToComplete(orderId: number, proNumber: string = ''): Promise<IShippingOrderModel> {
		return await this.httpService.patch(`/shipping/moveToComplete?orderId=${orderId}`, { proNumber });
	}

	async generateShippingLabelPdf(orderId: number): Promise<any> {
		return Promise.resolve(await this.httpService.get("/shipping/generateShippingLabelPDF", { orderId: orderId }));
	}

	async getCustomShippingAddresses(includeInactive: boolean = false): Promise<ICustomShippingAddressModel[]> {
		const params = {
			includeInactive: includeInactive
		}
		return this.httpService.get("/shipping/getCustomShippingAddresses", params);
	}

	async addCustomShippingAddress(customShippingAddressModel: ICustomShippingAddressModel): Promise<ICustomShippingAddressModel> {
		return this.httpService.post("/shipping/addCustomShippingAddress", customShippingAddressModel);
	}

	async updateCustomShippingAddress(customShippingAddressModel: ICustomShippingAddressModel): Promise<ICustomShippingAddressModel> {
		return this.httpService.post("/shipping/updateCustomShippingAddress", customShippingAddressModel);
	}
}
