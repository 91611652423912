export interface IFabricColor {
	name: string;
	color: string;
}

export class FabricColor implements IFabricColor {
	constructor(name: string, color: string) {
		this.name = name;
		this.color = color;
	}

	name: string;
	color: string;
}