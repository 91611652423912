import { IUserPermissionModel } from "./user-permission.model";
import { IContactModel, ContactModel } from "../contacts/contact.model";
import { UtilsService } from "../../services/utils/utils.service";
import * as moment from "moment";

export interface IUserModel {
	userId: number;
	uuid: string;
	companyId: number;
	userRoleId: number;
	userRoleName: string;
	employeeTypeId: number;
	employeeTypeName: string;
	userName: string;
	password: string;
	profilePictureUrl: string;
	startDate: Date;
	separationDate: Date;
	regularRate: number;
	overtimeRate: number;
	emergencyRate: number;
	notes: string;
	showOnSchedule: boolean;
	active: boolean;
	contact: IContactModel;
	userPermissions: IUserPermissionModel[];
}

export class UserModel implements IUserModel {
	userId: number;
	uuid: string;
	companyId: number;
	userRoleId: number;
	userRoleName: string;
	employeeTypeId: number;
	employeeTypeName: string;
	userName: string;
	profilePictureUrl: string;
	password: string;
	startDate: Date;
	separationDate: Date;
	regularRate: number;
	overtimeRate: number;
	emergencyRate: number;
	notes: string;
	showOnSchedule: boolean;
	active: boolean;
	contact: IContactModel;
	userPermissions: IUserPermissionModel[];

	public static getNewUserModel() {
		const newUserModel = new UserModel();

		newUserModel.userId = 0;
		newUserModel.userRoleId = null;
		newUserModel.userRoleName = null;
		newUserModel.uuid = UtilsService.newGuid();
		newUserModel.contact = ContactModel.getNewContactModel();
		newUserModel.startDate = moment().startOf("day").toDate();
		newUserModel.active = true;
		newUserModel.userPermissions = null;

		return newUserModel;

	}
}
