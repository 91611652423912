import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IUserInfoModel, ICompanyInfoModel, IInitModel, IUserPermissionModel } from "@models";

@Injectable()
export class GlobalsService {
	public static isDebug: boolean = false;
	public static isStaging: boolean = false;
	public static isLoggedIn: boolean;
	public static isNovaPoolCovers: boolean = false;

	public static companyInfo: ICompanyInfoModel;
	public static userInfo: IUserInfoModel;

	constructor(private http: HttpClient) {}

	setDebug() {
		GlobalsService.isDebug = true;
	}

	setStaging() {
		GlobalsService.isStaging = true;
	}

	setIsNovaPoolCovers() {
		GlobalsService.isNovaPoolCovers = true;
	}

	public static get apiUrl(): string {
		if (GlobalsService.isDebug === true)
			return "http://devapi.integrapoolcovers.com";
		else if (GlobalsService.isStaging === true)
			return "https://stagingapi.integrapoolcovers.com";
		else
			return "https://api.integrapoolcovers.com";
	}
	public static get jwtToken(): string {
		return localStorage.getItem('jwt_token') || '';
	}

	async isLoggedIn(): Promise<any> {
		try {
			if (window.location.href.toLowerCase().indexOf("/login") >= 0) {
				GlobalsService.isLoggedIn = false;
				return Promise.resolve(false);
			}

			GlobalsService.isLoggedIn = false;
			if (GlobalsService.jwtToken) {
				try {
					GlobalsService.isLoggedIn = <boolean>(await this.http.get("/init/validateLogin?jwtToken=" + GlobalsService.jwtToken).toPromise());
				}
				catch (err) {
					localStorage.removeItem("jwt_token");
					window.location.href = "/";
				}
			}

			return Promise.resolve(GlobalsService.isLoggedIn);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}


	async init(jwtToken: string = ''): Promise<void> {
		jwtToken = jwtToken || GlobalsService.jwtToken;

		const initUri = `${GlobalsService.apiUrl}/init/init?jwtToken=${jwtToken}`;
		const initModel: IInitModel = await this.http.get<IInitModel>(initUri).toPromise();

		if (initModel.isLoggedIn === true) {
			localStorage.setItem('jwt_token', jwtToken);
			GlobalsService.isLoggedIn = true;
			GlobalsService.companyInfo = initModel.companyInfoModel;
			GlobalsService.userInfo = initModel.userInfoModel;
		}

		return Promise.resolve();
	}

	public static checkPermission(feature: string, actions: string): boolean {
		const permissionFeature: IUserPermissionModel[] = GlobalsService.userInfo.userPermissions.filter((p) => { return p.feature.toLowerCase() === feature.toLowerCase() });

		let isGranted = false;

		let actionsArray = actions.split('|');
		actionsArray.forEach((a) => {
			let featureAction: IUserPermissionModel = permissionFeature.find((f) => { return f.action.toLowerCase() === a.toLowerCase() });
			if (!featureAction) {
				alert("Feature: " + feature + " | Action: " + a + " was not found.");
				return false;
			}

			if (featureAction.granted === true)
				isGranted = true;
		})

		return isGranted;
	}

	public static get isIntegra(): boolean {
		if (GlobalsService.isLoggedIn === false)
			return false;

		return GlobalsService.companyInfo.companyId === 1;
	}

	public static get isAdmin(): boolean {
		if (GlobalsService.isLoggedIn === false)
			return false;

		return GlobalsService.userInfo.userRole === 'Admin';
	}

	async logout(logoutType: string) {
		await this.http.get("/login/logout?logoutType=" + logoutType).toPromise();
		localStorage.removeItem('jwt_token');
		GlobalsService.isLoggedIn = false;
		GlobalsService.companyInfo = null;
		GlobalsService.userInfo = null;
	}
}