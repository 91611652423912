import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IPicklistOrderModel, IPicklistFulfillmentModel } from '@models';

@Injectable()
export class PicklistService {
	constructor(private httpService: HttpService) { }

	async getPicklistOrdersForStation(station: string, factory: string): Promise<IPicklistOrderModel[]> {
		return Promise.resolve(await this.httpService.get("/picklist/getPicklistOrdersForStation", { station: station, factory: factory }));
	}

	async getPicklistForFulfillment(orderId: number): Promise<IPicklistFulfillmentModel> {
		return Promise.resolve(await this.httpService.get("/picklist/getPicklistForFulfillment", { orderId: orderId }));
	}

	async setStationComplete(orderId: number, station: string) {
		return Promise.resolve(await this.httpService.patch(`/picklist/setStationComplete?orderId=${orderId}`, station));
	}

	async generatePicklistPdf(orderId: number): Promise<any> {
		return Promise.resolve(await this.httpService.get("/picklist/generatePicklistPDF", { orderId: orderId }));
	}

}
