export enum OrderEngineSpecTypes { string, number }

export interface IOrderEngineSpecModel {
	specName: string;
	specType: OrderEngineSpecTypes;
	specValues: string[];
}

export class OrderEngineSpecModel implements IOrderEngineSpecModel {
	specName: string;
	specType: OrderEngineSpecTypes;
	specValues: string[];
}
