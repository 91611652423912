import { Injectable } from "@angular/core";
import { ICustomerSearchListModel, ICustomerModel, IWarrantyRegistrationModel, IDocumentModel } from '@models';
import { HttpService, UtilsService } from '@services';
import { ISlickFileModel } from 'slick-components';

@Injectable()
export class WarrantyService {
	constructor(private httpService: HttpService) { }

	async getCustomerSearch(searchString: string): Promise<ICustomerSearchListModel[]> {
		const params = { searchString: searchString };

		return await this.httpService.get("warranty/getCustomerSearch", params);
	}

	async getCustomer(customerId: number): Promise<ICustomerModel> {
		const params = { customerId: customerId };

		return await this.httpService.get("warranty/getCustomer", params);
	}

	async register(warrantyRegistrationModel: IWarrantyRegistrationModel, photos: ISlickFileModel[]): Promise<IWarrantyRegistrationModel> {
		warrantyRegistrationModel = await this.httpService.post("warranty/register", warrantyRegistrationModel);
		if (photos && photos.length > 0) {
			let formData: FormData = new FormData();
			photos.forEach(file => {
				let fileCopy: IDocumentModel = UtilsService.clone(file);
				delete fileCopy.file;
				delete fileCopy.thumbnailBase64Image;
				let fileJSON = JSON.stringify(fileCopy);
				formData.append('image_' + file.uuid, fileJSON);
			})

			await this.httpService.postMultipart(`warranty/addWarrantyPhotos?warrantyRegistrationId=${warrantyRegistrationModel.warrantyRegistrationId}`, formData);
		}

		await this.httpService.get("warranty/sendWarrantyEmail", { warrantyRegistrationId: warrantyRegistrationModel.warrantyRegistrationId });

		return warrantyRegistrationModel;
	}

	async getWarrantyRegistration(warrantyRegistrationId: number): Promise<IWarrantyRegistrationModel> {
		return await this.httpService.get("/warranty/getWarrantyRegistration", { warrantyRegistrationId: warrantyRegistrationId })
	}
}
