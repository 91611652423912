import { Component } from "@angular/core";
import { GlobalsService } from "@services";

@Component({
	selector: "header-bar",
	templateUrl: "header-bar.component.html",
	styleUrls: ['header-bar.component.css']
})

export class HeaderBarComponent {
	firstName: string;
	profilePictureUrl: string;

	constructor() {
		if (GlobalsService.isLoggedIn) {
			this.firstName = GlobalsService.userInfo.firstName;
			this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;
		}
	}
}