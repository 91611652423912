<div class="header text-white p-0">

	<div class="menu-right">
		<notifications></notifications>
		<div class="profile-container" [routerLink]="['/settings/userSettings']">
			<div class="profile-name text-light">{{firstName}}</div>
			<img *ngIf="profilePictureUrl" [src]="profilePictureUrl" />
		</div>
	</div>
</div>

