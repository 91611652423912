export interface IDropdownModel {
	id: number | string;
	text: string;
}

export class DropdownModel implements IDropdownModel {
	constructor(id: number | string, text: string) {
		this.id = id;
		this.text = text;
	}

	id: number | string;
	text: string;
}