import { Component, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalsService, SleepService, UtilsService } from "@services";

@Component({
	selector: "side-menu",
	templateUrl: "side-menu.component.html",
	styleUrls: ['side-menu.component.css']
})

export class SideMenuComponent implements AfterViewInit {
	expanded: boolean;
	overlayExpanded: boolean = false;
	collapseIn: boolean = false;
	expandOut: boolean = false;
	firstName: string;
    profilePictureUrl: string;
	canAccessCustomers: boolean;
	canAccessOrders: boolean;
	canAccessMasterProductionSchedule: boolean;
	canAccessShipping: boolean;
	canAccessUsers: boolean;
	canAccessCompanies: boolean;
	canAccessHelpPages: boolean;
	canAccessOrderEngine: boolean;
	canResetPasswords: boolean;
	canAccessPriceLevels: boolean;
	canAccessItems: boolean;
	canAccessSetup: boolean;
	canAccessReports: boolean;
	isLoggedIn: boolean;
	hoverExpandTimeout: NodeJS.Timer;
	hoverCollapseTimeout: NodeJS.Timer;
	isIntegra = GlobalsService.isIntegra;
	isNovaPoolCovers = GlobalsService.isNovaPoolCovers;

	constructor(private globalsService: GlobalsService, private router: Router) {
		const menuState = localStorage.getItem("menuState") || "expanded";
		this.expanded = (menuState === "expanded");
		this.isLoggedIn = GlobalsService.isLoggedIn;

        if (this.isLoggedIn) {
			this.canAccessCustomers = GlobalsService.checkPermission("Customers", "View");
			this.canAccessOrders = GlobalsService.checkPermission("Orders", "View");
			this.canAccessMasterProductionSchedule = GlobalsService.checkPermission("MasterProductionSchedule", "View");
			this.canAccessShipping = GlobalsService.checkPermission("Shipping", "View");
			this.canAccessUsers = GlobalsService.checkPermission("Users", "View");
			this.canAccessCompanies = GlobalsService.companyInfo.companyId === 1 && GlobalsService.isAdmin;
			this.canAccessHelpPages = GlobalsService.companyInfo.companyId === 1 && GlobalsService.isAdmin;
			this.canAccessOrderEngine = GlobalsService.companyInfo.companyId === 1 && GlobalsService.isAdmin;
			this.canResetPasswords = GlobalsService.companyInfo.companyId === 1 && GlobalsService.isAdmin;
			this.canAccessPriceLevels = GlobalsService.checkPermission("PriceLevels", "Access");
			this.canAccessItems = GlobalsService.checkPermission("Items", "Access");
			this.canAccessReports = GlobalsService.checkPermission("Reports", "Access");
			this.canAccessSetup = this.isIntegra;
			this.firstName = GlobalsService.userInfo.firstName;
			this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;
		}
	}

	ngAfterViewInit() {
		if (!this.expanded)
			document.getElementById("main-area-container").classList.add('menu-collapsed');
	}

	async collapseMenu() {
		localStorage.setItem("menuState", "collapsed")
		document.getElementById("main-area-container").classList.add('menu-collapsed');

		clearTimeout(this.hoverExpandTimeout);
		clearTimeout(this.hoverCollapseTimeout);

		this.expanded = false;
		this.overlayExpanded = false;
		this.collapseIn = false;
		this.expandOut = false;
	}

	expandMenu() {
		localStorage.setItem("menuState", "expanded")
		document.getElementById("main-area-container").classList.remove('menu-collapsed');

		clearTimeout(this.hoverExpandTimeout);
		clearTimeout(this.hoverCollapseTimeout);

		this.expanded = true;
		this.overlayExpanded = false;
		this.collapseIn = false;
		this.expandOut = false;

	}

	async onMouseEnter() {
		clearTimeout(this.hoverExpandTimeout);
		clearTimeout(this.hoverCollapseTimeout);

		if (this.expanded || this.overlayExpanded)
			return;

		this.hoverExpandTimeout = setTimeout(async () => {
			this.expandOut = true;
			await SleepService.sleep(200);
			this.overlayExpanded = true;
			this.collapseIn = false;
			this.expandOut = false;
		}, 500);
	}

	async onMouseLeave(e: MouseEvent) {
		clearTimeout(this.hoverExpandTimeout);
		clearTimeout(this.hoverCollapseTimeout);

		if (this.expanded || !this.overlayExpanded)
			return;

		// If the mouse is going to the settings popover, don't collapse
		if (UtilsService.checkParentClassExists((<HTMLElement>e.target), "slick-popover"))
			return;
		
		this.hoverCollapseTimeout = setTimeout(async () => {
			this.overlayExpanded = false;
			this.collapseIn = true;
			await SleepService.sleep(200);
			this.collapseIn = false;
			this.expandOut = false;
		}, 100);
	}

	async logout() {
		await this.globalsService.logout("desktop");
		this.router.navigate(['/login']);
	}
}