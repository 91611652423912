import { UtilsService } from "../../services/utils/utils.service";

export interface ILineItem {
	uuid: string;
	sortOrder: number;
	itemId: number;
	lineItemTypeId: number;
	sku: string;
	description: string;
	quantity: number;
	cost: number;
	price: number;
	isSystemLineItem: boolean;
	taxable: boolean;
}

export class LineItem implements ILineItem {
	uuid: string;
	sortOrder: number;
	itemId: number;
	lineItemTypeId: number;
	sku: string;
	description: string;
	quantity: number;
	cost: number;
	price: number;
	isSystemLineItem: boolean;
	taxable: boolean;

	static getNewLineItem(): ILineItem {
		const newLineItem = new LineItem();
		newLineItem.uuid = UtilsService.newGuid();

		return newLineItem;
	}
}