import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ICustomerSearchListModel, ICustomerModel, ICustomerGridModel } from "@models";

@Injectable()
export class CustomersService {
	constructor(private httpService: HttpService) { }

	async getCustomer(customerId: number): Promise<ICustomerModel> {
		return Promise.resolve(await this.httpService.get("/customers/getCustomer", { customerId: customerId }));
	}

	async getCustomerForGrid(customerId: number): Promise<ICustomerGridModel> {
		return Promise.resolve(await this.httpService.get("/customers/getCustomerForGrid", { customerId: customerId }));
	}

	async getCustomerSearchListModel(customerId: number): Promise<ICustomerSearchListModel> {
		return Promise.resolve(await this.httpService.get("/customers/getCustomerSearchListModel", { customerId: customerId }));
	}

	async getCustomersForInfiniteScroll(pageCount: number, searchString: string) {
		const params = {
			pageCount,
			searchString
		}

		return Promise.resolve(await this.httpService.get("/customers/getCustomersForInfiniteScroll", params));
	}

	async updateCustomer(customerModel: ICustomerModel): Promise<ICustomerModel> {
		if (customerModel.customerId === 0) 
			return await this.httpService.post("/customers/addCustomer", customerModel);
		else 
			return await this.httpService.post("/customers/updateCustomer", customerModel)
	}

	async deleteCustomer(customerId: number): Promise<boolean> {
		return Promise.resolve(await this.httpService.delete("/customers/deleteCustomer", { customerId: customerId }));
	}

	async getCustomerSearchResults(searchString: string): Promise<ICustomerSearchListModel[]> {
		const params = {
			searchString: searchString
		}

		return Promise.resolve(await this.httpService.get("/customers/getCustomerSearchResults", params));
	}

	async checkDuplicateCustomer(customer: ICustomerModel): Promise<ICustomerModel> {
		return await this.httpService.post("/customers/checkDuplicateCustomer", customer);
	}
}
