import { Injectable, ElementRef } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class UtilsService {
	static newGuid(): string {
		let d = new Date().getTime();
		let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	}

	static waitForElement(elementSelector: string, iterationTimeout = 50): Promise<HTMLElement> {
		let infiniteLoopCheck = 10;
		let iterationId: NodeJS.Timer;
		const p = new Promise<HTMLElement>((resolve, reject) => {
			iterationId = setInterval(() => {
				const el = <HTMLElement>document.querySelector(elementSelector);
				if (el) {
					clearInterval(iterationId);
					resolve(el);
				}

				if (--infiniteLoopCheck <= 0) {
					clearInterval(iterationId);
					alert("Loop Check failed trying to find " + elementSelector);
					reject();
				}

			}, 100);

		});

		return p;
	}

	static waitForElementRef(elementRef: ElementRef, iterationTimeout = 50): Promise<ElementRef> {
		let infiniteLoopCheck = 10;
		let iterationId: NodeJS.Timer;
		const p = new Promise<ElementRef>((resolve, reject) => {
			iterationId = setInterval(() => {
				const el = <HTMLElement>elementRef.nativeElement;
				if (el && el.clientHeight && el.clientWidth) {
					clearInterval(iterationId);
					resolve(elementRef);
				}

				if (--infiniteLoopCheck <= 0) {
					clearInterval(iterationId);
					alert("Loop Check failed trying to find element");
					reject();
				}
			}, iterationTimeout);

		});

		return p;
	}

	static getDeepObject(obj: any, objName: string): any {
		const dotSplit = objName.split(".");

		let currentObj = obj;
		dotSplit.forEach((o) => {
			if (currentObj === null || currentObj === undefined)
				return null;

			currentObj = currentObj[o];
		});

		return currentObj;
	}

	static setDeepObject(obj: any, objName: string, val: any) {
		const dotSplit = objName.split(".");

		let prevObj = obj;
		let currentObj = obj;
		dotSplit.forEach((o) => {
			if (currentObj === null || currentObj === undefined)
				return null;

			prevObj = currentObj;
			currentObj = currentObj[o];
		});

		prevObj[dotSplit[dotSplit.length - 1]] = val;

		return currentObj;
	}

	static clone(obj: any): any {
		if (obj === null || obj === undefined)
			return obj;

		let returnObj = JSON.parse(JSON.stringify(obj));
		returnObj = this.dateSanitize(returnObj);

		return returnObj;
	}

	static dateSanitize(obj: any, infiniteLoopCheck: number = 0): any {
		return JSON.parse(JSON.stringify(obj));
	}
	//static dateSanitize(obj: any, infiniteLoopCheck: number = 0): any {
	//	infiniteLoopCheck++;

	//	if (infiniteLoopCheck > 20) {
	//		console.error("infinte loop");
	//		return obj;
	//	}

	//	if (obj === null || obj === undefined)
	//		return obj;

	//	// If this is an atomic type (string, bool, number), just return the atomic object
	//	if (typeof obj !== "object")
	//		return obj;

	//	let returnObj;
	//	if (Array.isArray(obj)) {
	//		returnObj = [];
	//		obj.forEach(o => returnObj.push(this.dateSanitize(o, infiniteLoopCheck)));
	//	}
	//	else {
	//		returnObj = {};

	//		for (const key in obj) {
	//			if (obj[key] === null)
	//				returnObj[key] = null;
	//			else if (Array.isArray(obj[key])) {
	//				returnObj[key] = [];
	//				obj[key].forEach(o => returnObj[key].push(this.dateSanitize(o, infiniteLoopCheck)));
	//			}
	//			else if (typeof obj[key] === "object")
	//				returnObj[key] = this.dateSanitize(obj[key], ++infiniteLoopCheck);
	//			else if (/^([0-9]{4}-(0[1-9]|10|11|12)-([012][0-9]|30|31)T([01][0-9]|[2][0123]):([0-5][0-9]):([0-5][0-9])(.[0-9]{0,})?(z|Z))$/.test(obj[key]))
	//				returnObj[key] = moment(obj[key], moment.ISO_8601).toDate();
	//			else {
	//				if (typeof obj[key] === 'string' && moment(obj[key], moment.ISO_8601).isValid()) {
	//					const checkVal: string = <string>obj[key];
	//					if (checkVal.indexOf("Z") === checkVal.length - 1) {
	//						alert("It appears that " + key + " is a date, but it didn't pass the regex.  Value: " + obj[key] + ".  Please let Scott know.");
	//					}
	//				}
	//				returnObj[key] = obj[key];
	//			}
	//		}
	//	}

	//	return returnObj;
	//}

	static round(num: number, decimals: number): number {
		return Number(Math.round(parseFloat(num + 'e' + decimals)) + 'e-' + decimals);
	}

	static checkParentClassExists(el: HTMLElement, className: string): boolean {
		let currentEl: HTMLElement = el;
		let hasSelector = false;
		while (currentEl && currentEl !== document.body) {
			if (currentEl.classList.contains(className)) {
				hasSelector = true;
				break;
			}
			currentEl = currentEl.parentElement;
		}

		return hasSelector;
	}

	static checkParentIdExists(el: HTMLElement, idName: string): boolean {
		let currentEl: HTMLElement = el;
		let hasSelector = false;
		while (currentEl && currentEl !== document.body) {
			if (currentEl.getAttribute("id") === idName) {
				hasSelector = true;
				break;
			}
			currentEl = currentEl.parentElement;
		}

		return hasSelector;
	}

	static findParent(el: HTMLElement, className: string): HTMLElement {
		let currentEl: HTMLElement = el;
		while (currentEl && currentEl !== document.body) {
			if (currentEl.classList && currentEl.classList.contains(className)) {
				return currentEl;
			}
			currentEl = <HTMLElement>currentEl.parentElement;
		}

		return null;
	}


	static getClosestSibling(el: HTMLElement, className: string): HTMLElement {
		let currentEl: HTMLElement = el;
		while (currentEl && currentEl !== document.body) {
			if (currentEl.classList && currentEl.classList.contains(className)) {
				return currentEl;
			}
			currentEl = <HTMLElement>currentEl.previousSibling;
		}

		return null;
	}

	static getHashCode = function (str: string) {
		let hash: number = 0;
		let chr: number;

		if (str.length === 0) return hash;
		for (let i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return Math.abs(hash);
	};
}

