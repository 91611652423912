import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IPriceLevelModel, IDropdownModel } from "@models";

@Injectable()
export class PriceLevelsService {
	constructor(private httpService: HttpService) { }

	async getAllPriceLevelsForGrid(showInactive: boolean): Promise<IPriceLevelModel[]> {
		return await this.httpService.get("/priceLevels/getAllPriceLevelsForGrid", { showInactive: showInactive });
	}

	async getPriceLevelsForDropDown(): Promise<IDropdownModel[]> {
		return await this.httpService.get("/priceLevels/getPriceLevelsForDropDown");
	}

	async getPriceLevel(priceLevelId): Promise<IPriceLevelModel> {
		return await this.httpService.get("/priceLevels/getPriceLevel", { priceLevelId: priceLevelId });
	}

	async updatePriceLevel(priceLevelModel: IPriceLevelModel): Promise<IPriceLevelModel> {
		if (priceLevelModel.priceLevelId === 0)
			return await this.httpService.post("/priceLevels/addPriceLevel", priceLevelModel);
		else
			return await this.httpService.post("/priceLevels/updatePriceLevel", priceLevelModel);
	}

	async exportPriceLevelToExcel(priceLevelId: number): Promise<any> {
		const params = {
			priceLevelId
		}

		return Promise.resolve(await this.httpService.get("/reporting/exportPriceLevelToExcel", params));
	}
}