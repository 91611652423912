export interface IItemModel {
	itemId: number;
	sku: string;
	description: string;
	tags: string;
	cost: number;
	price: number;
	weight: number;
	imageUrl: string;
	purchasable: boolean;
	discontinued: boolean;
	locked: boolean;
	active: boolean;
}

export class ItemModel implements IItemModel {
	itemId: number;
	sku: string;
	description: string;
	tags: string;
    cost: number;
	price: number;
	weight: number;
	imageUrl: string;
	purchasable: boolean;
	discontinued: boolean;
	locked: boolean;
	active: boolean;

	public static getNewItemModel(): IItemModel {
		const newItem = new ItemModel();
		newItem.itemId = 0;
        newItem.cost = 0.0;
		newItem.purchasable = true;
		newItem.discontinued = false;
		newItem.locked = false;
		newItem.active = true;

		return newItem;
	}
}