import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IUserModel } from "@models";

@Injectable()
export class UsersService {
	constructor(private httpService: HttpService) { }

	async getAllUsers(searchString: string, showInactive: boolean): Promise<IUserModel[]> {
		let params = {
			searchString: searchString,
			showInactive: showInactive
		}

		return this.httpService.get("/users/getAllUsers", params);
	}

	async getAllUsersForCompany(companyId: number): Promise<IUserModel[]> {
		let params = {
			companyId: companyId
		}

		return this.httpService.get("/users/getAllUsersForCompany", params);
	}

	async getUser(userId: number): Promise<IUserModel> {
		return this.httpService.get("/users/getUser", { userId: userId });
	}

	async addUser(userModel: IUserModel): Promise<IUserModel> {
		return this.httpService.post("/users/addUser", userModel);
	}

	async updateUser(userModel: IUserModel): Promise<IUserModel> {
		return this.httpService.post("/users/updateUser", userModel);
	}

	async isDuplicateUserName(userName: string): Promise<boolean> {
		return this.httpService.get("/users/isduplicateusername", { userName: userName });

	}

	async resetPassword(userUuid: string, newPassword: string) {
		const params = {
			userUuid,
			newPassword
		}
		return this.httpService.patch('/users/resetPassword', params);
	}

}