import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ILoginResultModel, ILoginModel } from "@models";

@Injectable()
export class LoginServices {
	constructor(private httpService: HttpService) { }

	async validateLogin(loginModel: ILoginModel): Promise<ILoginResultModel> {
		return await this.httpService.post('/login/validateLogin', loginModel);
	}

	async setInitialPassword(loginModel: ILoginModel): Promise<ILoginResultModel> {
		return await this.httpService.post('/login/setInitialPassword', loginModel);
	}

	async isFirstSignIn(userName: string): Promise<boolean> {
		return await this.httpService.get('/login/isFirstSignIn', { userName: userName });
	}
}