import { Injectable } from "@angular/core";
import { HelpPageModel, IHelpPageModel } from '@models';
import { HttpService } from "@services";

@Injectable()
export class HelpPagesStore {
	private static _helpPages: IHelpPageModel[]

	constructor(private httpService: HttpService) { }

	//*****************************************************************************
	//* Help Pages
	//*****************************************************************************
	async init() {
		HelpPagesStore._helpPages = await this.httpService.get("/helpPages/getAllHelpPages");
	}

	getAllHelpPages(): IHelpPageModel[] {
		return HelpPagesStore._helpPages;
	}

	getHelpPage(helpPageId: string): HelpPageModel {
		const helpPage = HelpPagesStore._helpPages.find(x => x.helpPageId === helpPageId);

		if (helpPage)
			return helpPage;
		else
			return new HelpPageModel();


	}

	async refreshHelpPages(): Promise<IHelpPageModel[]> {
		HelpPagesStore._helpPages = await this.httpService.get("/helpPages/getAllHelpPages");

		return HelpPagesStore._helpPages;
	}
}
