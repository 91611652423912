export interface IOrderSpecs {
	orderSpecId: number;
	orderType: string;
	modelType: string;
	allInOneSystem?: boolean;
	unitLocation: string;
	poolType: string;
	deckType: string;
	guideType: string;
	encapsulationType: string;
	boxPerimeterEncapsulation: string;
	vinylLinerRadiusCorner: string;
	includeTrackProtector: string;
	includeClipOnCoping: string;
	clipOnCopingType: string;
	includeMatchingLidDescender: string;
	lidType: string;
	lidBrackets?: number;
	heavyDutyLidBrackets?: boolean;
	benchBrackets?: number;
	walkOnLidEndBrackets?: boolean;
	walkOnLidBracketAnchoring: string;
	walkOnLidBracketExtension: string;
	includeReusableCantileverDeckFormKit: string;
	housingType: string;
	housingMountingKit: string;
	motorType: string;
	motorSide: string;
	guideSpaceFeet?: number;
	guideSpaceInches?: number;
	guideLengthFeet?: number;
	guideLengthInches?: number;
	totalSqft: number;
	systemSqft: number;
	slack?: number;
	slackOverride?: boolean;
	rollerWrap?: number;
	rollerWrapOverride?: boolean;
	oem: string;
	fabricColor: string;
	webbingType: string;
	ropeOrLoop: string;
	leadingEdgeBarType: string;
	rollupTubeType: string;
	sliderType: string;
	attachSlider?: boolean;
	bondWire?: boolean;
	ropeAttachment: string;
	coverOptions: string;
	cornerTypeNotes: string;
	preShippedAllPhase1: boolean;
	preShippedCoping: boolean;
	preShippedEncapsulation: boolean;
	preShippedHousing: boolean;
	preShippedHousingMount: boolean;
	preShippedLeadingEdgeBar: boolean;
	preShippedLidAndBrackets: boolean;
	preShippedMechanism: boolean;
	preShippedRollUpTube: boolean;
	preShippedTrack: boolean;

}

export class OrderSpecs implements IOrderSpecs {
	orderSpecId: number;
	orderType: string;
	modelType: string;
	allInOneSystem?: boolean;
	unitLocation: string;
	poolType: string;
	deckType: string;
	guideType: string;
	encapsulationType: string;
	boxPerimeterEncapsulation: string;
	vinylLinerRadiusCorner: string;
	includeTrackProtector: string;
	includeClipOnCoping: string;
	clipOnCopingType: string;
	includeMatchingLidDescender: string;
	lidType: string;
	lidBrackets?: number;
	heavyDutyLidBrackets?: boolean;
	benchBrackets?: number;
	walkOnLidEndBrackets?: boolean;
	walkOnLidBracketAnchoring: string;
	walkOnLidBracketExtension: string;
	includeReusableCantileverDeckFormKit: string;
	housingType: string;
	housingMountingKit: string;
	motorType: string;
	motorSide: string;
	guideSpaceFeet?: number;
	guideSpaceInches?: number;
	guideLengthFeet?: number;
	guideLengthInches?: number;
	totalSqft: number;
	systemSqft: number;
	slack?: number;
	slackOverride?: boolean;
	rollerWrap?: number;
	rollerWrapOverride?: boolean;
	oem: string;
	fabricColor: string;
	webbingType: string;
	ropeOrLoop: string;
	leadingEdgeBarType: string;
	rollupTubeType: string;
	sliderType: string;
	attachSlider?: boolean;
	bondWire?: boolean;
	ropeAttachment: string;
	coverOptions: string;
	cornerTypeNotes: string;
	preShippedAllPhase1: boolean;
	preShippedCoping: boolean;
	preShippedEncapsulation: boolean;
	preShippedHousing: boolean;
	preShippedHousingMount: boolean;
	preShippedLeadingEdgeBar: boolean;
	preShippedLidAndBrackets: boolean;
	preShippedMechanism: boolean;
	preShippedRollUpTube: boolean;
	preShippedTrack: boolean;

	constructor() {
		this.allInOneSystem = false;
		this.slackOverride = false;
		this.rollerWrap = 3;
		this.rollerWrapOverride = false;
		this.attachSlider = true;
		this.bondWire = false;
	}
}