export interface ICustomShippingAddressModel {
	customShippingAddressId: number;
	description: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	active: boolean;
}

export class CustomShippingAddressModel implements ICustomShippingAddressModel {
	constructor() {
		this.active = true;
	}

	customShippingAddressId: number;
	description: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	fullAddress: string;
	cityStatePostal: string;
	active: boolean;
}