import { UtilsService } from "../../services/utils/utils.service";

export interface IEmailAddressModel {
	emailAddressId: number;
	uuid: string;
	emailAddressType: string;
	emailAddress: string;
	isDefault: boolean;
	isSecondary: boolean;
	active: boolean;
}

export class EmailAddressModel implements IEmailAddressModel {
	emailAddressId: number;
	uuid: string;
	emailAddressType: string;
	emailAddress: string;
	isDefault: boolean;
	isSecondary: boolean;
	active: boolean;

	public static getNewEmailAddressModel(isDefault?: boolean): IEmailAddressModel {
		isDefault = !!isDefault;
		const newEmailAddress: IEmailAddressModel = new EmailAddressModel();
		newEmailAddress.uuid = UtilsService.newGuid();
		newEmailAddress.emailAddressType = "Home";
		newEmailAddress.isDefault = isDefault;
		newEmailAddress.active = true;

		return newEmailAddress;
	}


}
