import { UtilsService } from "../../services";

export interface IOrderEngineDefinitionLineItemModel {
	uuid: string;
	orderEngineDefinitionId: number;
	lineItemType: string;
	lineItemAction: string;
	sku: string;
	description: string;
	quantity?: number;
	picklistStation: string;
	customCalc: string;
}

export class OrderEngineDefinitionLineItemModel implements IOrderEngineDefinitionLineItemModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}

	uuid: string;
	orderEngineDefinitionId: number;
	lineItemType: string;
	lineItemAction: string;
	sku: string;
	description: string;
	quantity?: number;
	picklistStation: string;
	customCalc: string;
}