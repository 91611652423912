import { ISlickTreeNodeModel, SlickTreeNodeModel } from "slick-components";
import { IOrderEngineDefinitionConditionModel } from "./order-engine-definition-condition.model";
import { IOrderEngineDefinitionLineItemModel } from "./order-engine-definition-line-item.model";

export interface IOrderEngineDefinitionModel extends ISlickTreeNodeModel {
	orderEngineDefinitionId: number;
	seq?: number
	description: string;
	treePath: string;
	active: boolean;

	conditions: IOrderEngineDefinitionConditionModel[];
	orderLineItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	orderLineItemsToDelete: IOrderEngineDefinitionLineItemModel[];
	picklistItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	picklistItemsToDelete: IOrderEngineDefinitionLineItemModel[];
	bomItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	bomItemsToDelete: IOrderEngineDefinitionLineItemModel[];
}

export class OrderEngineDefinitionModel extends SlickTreeNodeModel implements IOrderEngineDefinitionModel {
	orderEngineDefinitionId: number;
	seq?: number
	description: string;
	treePath: string;
	active: boolean;

	conditions: IOrderEngineDefinitionConditionModel[];
	orderLineItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	orderLineItemsToDelete: IOrderEngineDefinitionLineItemModel[];
	picklistItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	picklistItemsToDelete: IOrderEngineDefinitionLineItemModel[];
	bomItemsToAdd: IOrderEngineDefinitionLineItemModel[];
	bomItemsToDelete: IOrderEngineDefinitionLineItemModel[];
}