import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: "orders", pathMatch: 'full' },
	{ path: "customers", loadChildren: () => import('./customers/customers-page/customers-page.module').then(m => m.CustomersPageModule) },
	{ path: "items", loadChildren: () => import('./items/items-page/items-page.module').then(m => m.ItemsPageModule) },
	{ path: "landing", loadChildren: () => import('./landing/landing-page/landing-page.module').then(m => m.LandingPageModule) },
	{ path: "login", loadChildren: () => import('./login/login-page/login-page.module').then(m => m.LoginPageModule) },
	{ path: "master-production-schedule", loadChildren: () => import('./master-production-schedule/master-production-schedule-page/master-production-schedule-page.module').then(m => m.MasterProductionSchedulePageModule) },
	{ path: "shipping", loadChildren: () => import('./shipping/shipping-page/shipping-page.module').then(m => m.ShippingPageModule) },
	{ path: "orders", loadChildren: () => import('./orders/orders-page/orders-page.module').then(m => m.OrdersPageModule) },
	{ path: "reports", loadChildren: () => import('./reports/reports-page/reports-page.module').then(m => m.ReportsPageModule) },
	{ path: "setup/companies", loadChildren: () => import('./setup/companies/companies-page/companies-page.module').then(m => m.CompaniesPageModule) },
	{ path: "setup/help-pages", loadChildren: () => import('./setup/help-pages/help-page.module').then(m => m.HelpPageModule) },
	{ path: "setup/order-engine", loadChildren: () => import('./setup/order-engine/order-engine.module').then(m => m.OrderEngineModule) },
	{ path: "setup/price-levels", loadChildren: () => import('./setup/price-levels/price-levels-page/price-levels-page.module').then(m => m.PriceLevelsPageModule) },
	{ path: "setup/reset-passwords", loadChildren: () => import('./setup/reset-passwords/reset-passwords.module').then(m => m.ResetPasswordsModule) },
	{ path: "test", loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
	{ path: "users", loadChildren: () => import('./users/users-page/users-page.module').then(m => m.UsersPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
