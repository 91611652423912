<div class="side-menu bg-dark text-light" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave($event)">
	<div *ngIf="expanded === false" class="side-menu-collapsed" [ngClass]="{'overlay-expanded': overlayExpanded, 'side-menu-expand': expandOut, 'side-menu-collapse': collapseIn }">
		<div *ngIf="overlayExpanded" class="expand-icon" (click)="expandMenu()"><i class="far fa-thumbtack"></i></div>
		<div class="logo">
			<img *ngIf="!isNovaPoolCovers" [routerLink]="['/']" src="/assets/images/icon-circle.png" style="width: 30px; height: 30px;" />
			<img *ngIf="isNovaPoolCovers" [routerLink]="['/']" src="/assets/images/nova-icon-circle.png" style="width: 30px; height: 30px;" />
		</div>
		<table class="table-hover text-white noselect">
			<tbody>
				<tr *ngIf="canAccessOrders"><td [routerLink]="['/orders']" routerLinkActive="bg-primary"><i class="far fa-file-invoice"></i><span *ngIf="overlayExpanded">Orders</span></td></tr>
				<tr *ngIf="isIntegra && canAccessMasterProductionSchedule"><td [routerLink]="['/master-production-schedule']" routerLinkActive="bg-primary"><i class="far fa-check-square"></i><span *ngIf="overlayExpanded">Master Prod Sched</span></td></tr>
				<tr *ngIf="isIntegra && canAccessShipping"><td [routerLink]="['/shipping']" routerLinkActive="bg-primary"><i class="far fa-truck"></i><span *ngIf="overlayExpanded">Shipping</span></td></tr>
				<tr *ngIf="!isIntegra && canAccessCustomers"><td [routerLink]="['/customers']" routerLinkActive="bg-primary"><i class="far fa-users"></i><span *ngIf="overlayExpanded">Customers</span></td></tr>
				<tr *ngIf="canAccessItems"><td [routerLink]="['/items']" routerLinkActive="bg-primary"><i class="far fa-tag"></i><span *ngIf="overlayExpanded">Items</span></td></tr>
				<tr *ngIf="isIntegra && canAccessReports"><td [routerLink]="['/reports']" routerLinkActive="bg-primary"><i class="far fa-chart-bar"></i><span *ngIf="overlayExpanded">Reports</span></td></tr>
				<tr *ngIf="isIntegra && canAccessCompanies"><td [routerLink]="['/setup/companies']" routerLinkActive="bg-primary"><i class="far fa-building"></i><span *ngIf="overlayExpanded">Companies</span></td></tr>
				<tr *ngIf="isIntegra && canAccessHelpPages"><td [routerLink]="['/setup/help-pages']" routerLinkActive="bg-primary"><i class="far fa-question-circle"></i><span *ngIf="overlayExpanded">Help Pages</span></td></tr>
				<tr *ngIf="isIntegra && canAccessOrderEngine"><td [routerLink]="['/setup/order-engine']" routerLinkActive="bg-primary"><i class="far fa-screwdriver"></i><span *ngIf="overlayExpanded">Order Engine</span></td></tr>
				<tr *ngIf="isIntegra && canResetPasswords"><td [routerLink]="['/setup/reset-passwords']" routerLinkActive="bg-primary"><i class="far fa-key"></i><span *ngIf="overlayExpanded">Reset Passwords</span></td></tr>
				<tr *ngIf="canAccessPriceLevels"><td [routerLink]="['/setup/price-levels']" routerLinkActive="bg-primary"><i class="far fa-dollar-sign"></i><span *ngIf="overlayExpanded">Price Levels</span></td></tr>
				<tr *ngIf="canAccessUsers"><td [routerLink]="['/users']" routerLinkActive="bg-primary"><i class="far fa-user-tie"></i><span *ngIf="overlayExpanded">Users</span></td></tr>
				<tr><td (click)="logout()" routerLinkActive="bg-primary"><i class="far fa-sign-out" style="padding-left: 2px;"></i><span *ngIf="overlayExpanded">Sign Out</span></td></tr>
			</tbody>
		</table>
	</div>

	<div *ngIf="expanded === true" class="side-menu-expanded">
		<div class="collapse-icon" (click)="collapseMenu()"><i class="far fa-thumbtack" style="transform: rotate(90deg);"></i></div>
		<div class="logo">
			<img *ngIf="!isNovaPoolCovers" [routerLink]="['/']" src="/assets/images/logo.png" />
			<img *ngIf="isNovaPoolCovers" [routerLink]="['/']" src="/assets/images/nova-logo.png" style="width: 140px"/>
		</div>

		<table class="table-hover text-white table-bordered noselect">
			<tbody>
				<tr *ngIf="canAccessOrders"><td [routerLink]="['/orders']" routerLinkActive="bg-primary"><i class="far fa-file-invoice"></i> Orders</td></tr>
				<tr *ngIf="isIntegra && canAccessMasterProductionSchedule"><td [routerLink]="['/master-production-schedule']" routerLinkActive="bg-primary"><i class="far fa-check-square"></i>&nbsp;Master Prod Sched</td></tr>
				<tr *ngIf="isIntegra && canAccessShipping"><td [routerLink]="['/shipping']" routerLinkActive="bg-primary"><i class="far fa-truck"></i>&nbsp;Shipping</td></tr>
				<tr *ngIf="!isIntegra && canAccessCustomers"><td [routerLink]="['/customers']" routerLinkActive="bg-primary"><i class="far fa-users"></i> Customers</td></tr>
				<tr *ngIf="canAccessItems"><td [routerLink]="['/items']" routerLinkActive="bg-primary"><i class="far fa-tag"></i> Items</td></tr>
				<tr *ngIf="isIntegra && canAccessReports"><td [routerLink]="['/reports']" routerLinkActive="bg-primary"><i class="far fa-chart-bar"></i> Reports</td></tr>
				<tr *ngIf="isIntegra && canAccessCompanies"><td [routerLink]="['/setup/companies']" routerLinkActive="bg-primary"><i class="far fa-building"></i> Companies</td></tr>
				<tr *ngIf="isIntegra && canAccessHelpPages"><td [routerLink]="['/setup/help-pages']" routerLinkActive="bg-primary"><i class="far fa-question-circle"></i> Help Pages</td></tr>
				<tr *ngIf="isIntegra && canAccessOrderEngine"><td [routerLink]="['/setup/order-engine']" routerLinkActive="bg-primary"><i class="far fa-screwdriver"></i> Order Engine</td></tr>
				<tr *ngIf="isIntegra && canResetPasswords"><td [routerLink]="['/setup/reset-passwords']" routerLinkActive="bg-primary"><i class="far fa-key"></i>Reset Passwords</td></tr>
				<tr *ngIf="canAccessPriceLevels"><td [routerLink]="['/setup/price-levels']" routerLinkActive="bg-primary"><i class="far fa-dollar-sign"></i> Price Levels</td></tr>
				<tr *ngIf="canAccessUsers"><td [routerLink]="['/users']" routerLinkActive="bg-primary"><i class="far fa-user-tie"></i> Users</td></tr>
				<tr><td (click)="logout()"><i class="far fa-sign-out"></i> Sign Out</td></tr>
			</tbody>
		</table>
	</div>
</div>