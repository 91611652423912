import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

class FunctionLockModel {
	uuid: string;
	functionKey: string;
	infiniteLoopCheck: number = 100;
	promise: Promise<void>;
	lockCount: number;
	intervalId: NodeJS.Timer;
}

@Injectable()
export class FunctionLockService {
	private functionLocks: any = {};

	constructor() {}

	lock(functionKey: string, iterationTimeout: number = 200): Promise<void> {
		if (!this.functionLocks[functionKey])
			this.functionLocks[functionKey] = [];

		let functionLock = new FunctionLockModel();
		functionLock.functionKey = functionKey;
		functionLock.uuid = UtilsService.newGuid();
		functionLock.lockCount = this.functionLocks[functionKey].length + 1;
		functionLock.promise = new Promise<any>((resolve, reject) => {
			functionLock.intervalId = setInterval(() => {
				if (functionLock.lockCount === 1 || functionLock.infiniteLoopCheck-- === 0) {
					clearInterval(functionLock.intervalId);
					if (functionLock.infiniteLoopCheck === 0) {
						alert("Inifite loop detected for " + functionLock.functionKey);
					}
					resolve(null);
				}
			}, iterationTimeout);
		});

		
		this.functionLocks[functionKey].push(functionLock);

		return functionLock.promise;
	}

	release(functionKey: string): number {
		this.functionLocks[functionKey].splice(0, 1);
		this.functionLocks[functionKey].forEach((fl: FunctionLockModel) => fl.lockCount--);

		return this.functionLocks[functionKey].length;
	}
	
}

