<div *ngIf="showPage === true">
	<div *ngIf="isValidLoginPage === false" class="main-container">
		<div class="content">
			<side-menu></side-menu>
			<div id="main-area-container" class="main-area-container">
				<header-bar></header-bar>
				<div class="main-area">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isValidLoginPage === true">
		<router-outlet></router-outlet>
	</div>

	<http-error-dialog></http-error-dialog>
</div>