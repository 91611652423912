import { IContactModel, ContactModel } from "../contacts/contact.model";
import { UtilsService } from "../../services/utils/utils.service";

export interface ICustomerModel {
	customerId: number;
	uuid: string;
	version: number;
	isBuilder: boolean;
	active: boolean;

	contact: IContactModel;
}

export class CustomerModel implements ICustomerModel {
	customerId: number;
	uuid: string;
	version: number;
	isBuilder: boolean;
	active: boolean;

	contact: IContactModel;

	public static getNewCustomerModel() {
		const customerModel = new CustomerModel();

		customerModel.customerId = 0;
		customerModel.uuid = UtilsService.newGuid();
		customerModel.version = 0;
		customerModel.contact = ContactModel.getNewContactModel();
		customerModel.active = true;

		return customerModel;
	}

}
