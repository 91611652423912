import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IOrderModel, IOrderGridModel } from "@models";

@Injectable()
export class BOMService {
	constructor(private httpService: HttpService) { }

	async generateBOMPdf(orderId: number): Promise<any> {
		return Promise.resolve(await this.httpService.get("/bom/generateBOMPDF", { orderId: orderId }));
	}

	async generateBOMExcelFile(orderId: number): Promise<any> {
		return Promise.resolve(await this.httpService.get("/bom/generateBOMExcelFile", { orderId: orderId }));
	}
}
