import { IAddressModel, AddressModel } from "./address.model";
import { IEmailAddressModel, EmailAddressModel } from "./email-address.model";
import { IPhoneNumberModel, PhoneNumberModel } from "./phone-number.model";
import { UtilsService } from '@services';

export interface IContactModel {
	contactId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	companyName: string;
	displayName: string;
	defaultAddress: IAddressModel;
	defaultHome: IPhoneNumberModel;
	defaultWork: IPhoneNumberModel;
	defaultCell: IPhoneNumberModel;
	defaultAlt1: IPhoneNumberModel;
	defaultAlt2: IPhoneNumberModel;
	defaultEmailAddress: IEmailAddressModel;
	secondaryEmailAddress: IEmailAddressModel;
	addresses: IAddressModel[];
	phoneNumbers: IPhoneNumberModel[];
	emailAddresses: IEmailAddressModel[];
}

export class ContactModel implements IContactModel {
	contactId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	companyName: string;
	displayName: string;
	defaultAddress: IAddressModel;
	defaultHome: IPhoneNumberModel;
	defaultWork: IPhoneNumberModel;
	defaultCell: IPhoneNumberModel;
	defaultAlt1: IPhoneNumberModel;
	defaultAlt2: IPhoneNumberModel;
	defaultEmailAddress: IEmailAddressModel;
	secondaryEmailAddress: IEmailAddressModel;
	addresses: IAddressModel[];
	phoneNumbers: IPhoneNumberModel[];
	emailAddresses: IEmailAddressModel[];

	public static getNewContactModel(): IContactModel {
		const contactModel: IContactModel = new ContactModel();
		contactModel.contactId = 0;
		contactModel.defaultAddress = AddressModel.getNewAddressModel();
		contactModel.defaultHome = PhoneNumberModel.getNewPhoneNumberModel(true, "Home");
		contactModel.defaultWork = PhoneNumberModel.getNewPhoneNumberModel(true, "Work");
		contactModel.defaultCell = PhoneNumberModel.getNewPhoneNumberModel(true, "Cell");
		contactModel.defaultEmailAddress = EmailAddressModel.getNewEmailAddressModel(true);

		return contactModel;
	}

	public static cloneNewContactModel(contactModel: IContactModel): IContactModel {
		const newContactModel = UtilsService.clone(contactModel);

		newContactModel.contactId = 0;
		newContactModel.uuid = UtilsService.newGuid();
		newContactModel.addresses = newContactModel.addresses.map(a => {
			a.addressId = 0;
			a.uuid = UtilsService.newGuid();
			if (a.isDefault)
				newContactModel.defaultAddress = UtilsService.clone(a);

			return a;
		});

		newContactModel.phoneNumbers = newContactModel.phoneNumbers.map(p => {
			p.phoneNumberId = 0;
			p.uuid = UtilsService.newGuid();
			if (p.isDefault) {
				switch (p.phoneNumberType) {
					case "Home":
						newContactModel.defaultHome = UtilsService.clone(p);
						break;

					case "Work":
						newContactModel.defaultWork = UtilsService.clone(p);
						break;

					case "Cell":
						newContactModel.defaultCell = UtilsService.clone(p);
						break;
				}
			}

			return p;
		});

		newContactModel.emailAddresses = newContactModel.emailAddresses.map(e => {
			e.emailAddressId = 0;
			e.uuid = UtilsService.newGuid();

			if (e.isDefault)
				newContactModel.defaultEmailAddress = UtilsService.clone(e);

			return e;
		});

		return newContactModel;
	}

}
