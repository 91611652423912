import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IItemUsageModel, IOpenOrdersModel, IOrderCountModel, IVAROpenOrdersModel } from "@models";
import * as moment from 'moment';

@Injectable()
export class ReportingServices {
	constructor(private httpService: HttpService) {

	}

	async getOpenOrdersData(): Promise<IOpenOrdersModel> {
		return this.httpService.get("/reporting/getOpenOrdersData");
	}

	async exportOpenOrdersToExcel(): Promise<any> {
		return this.httpService.get("/reporting/exportOpenOrdersToExcel");
	}

	async getVAROpenOrdersData(companyId: number): Promise<IVAROpenOrdersModel> {
		return this.httpService.get(`/reporting/getVAROpenOrdersData?companyId=${companyId}`);
	}

	async exportVAROpenOrdersToExcel(companyId: number): Promise<any> {
		return this.httpService.get(`/reporting/exportVAROpenOrdersToExcel?companyId=${companyId}`);
	}

	async getOrderCountData(startDate: Date, endDate: Date, productionFacility: string, forCompanyId?: number): Promise<IOrderCountModel> {
		const params = {
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
			productionFacility: productionFacility,
			forCompanyId: (forCompanyId || 0),
		}

		return this.httpService.get("/reporting/getOrderCountData", params);
	}

	async exportOrderCountToExcel(startDate: Date, endDate: Date, productionFacility: string, forCompanyId?: number): Promise<any> {
		const params = {
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
			productionFacility: productionFacility,
			forCompanyId: (forCompanyId || 0)
		}

		return this.httpService.get("/reporting/exportOrderCountToExcel", params);
	}

	async getItemUsageData(itemNumber: string, startDate: Date, endDate: Date): Promise<IItemUsageModel> {
		const params = {
			itemNumber: itemNumber,
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
		}

		return this.httpService.get("/reporting/getItemUsageData", params);
	}

}