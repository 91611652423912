import { Injectable } from "@angular/core";
import { ICustomerModel, IOrderEngineDefinitionModel } from '@models';
import { HttpService } from "@services";
import { Subject } from 'rxjs';


@Injectable()
export class OrderEngineStore {
	private static _orderEngineDefinitions: IOrderEngineDefinitionModel[];	

	constructor(private httpService: HttpService) { }

	async init() {
		if (!OrderEngineStore._orderEngineDefinitions)
			OrderEngineStore._orderEngineDefinitions = await this.httpService.get("/orderEngine/getAllOrderEngineDefinitions");

		this.orderEngineDefinitionsSubject.next(OrderEngineStore._orderEngineDefinitions);
	}

	private orderEngineDefinitionsSubject: Subject<IOrderEngineDefinitionModel[]> = new Subject<IOrderEngineDefinitionModel[]>();
	get orderEngineDefinitionsStore(): Subject<IOrderEngineDefinitionModel[]> {
		return this.orderEngineDefinitionsSubject;
	}

	async getOrderEngineDefinitions(): Promise<IOrderEngineDefinitionModel[]> {
		if (!OrderEngineStore._orderEngineDefinitions)
			OrderEngineStore._orderEngineDefinitions = await this.httpService.get("/orderEngine/GetAllOrderEngineDefinitions");

		return OrderEngineStore._orderEngineDefinitions;
	}

	async getOrderEngineDefinition(uuid: string): Promise<IOrderEngineDefinitionModel> {
		return this.httpService.get(`/orderEngine/getOrderEngineDefinition?uuid=${uuid}`);
	}

	async addOrderEngineDefinition(model: IOrderEngineDefinitionModel): Promise<IOrderEngineDefinitionModel> {
		const orderEngineDefinition: IOrderEngineDefinitionModel = await this.httpService.post("/orderEngine/addOrderEngineDefinition", model);
		OrderEngineStore._orderEngineDefinitions.push(orderEngineDefinition);

		return orderEngineDefinition;
	}

	async updateOrderEngineDefinition(model: IOrderEngineDefinitionModel): Promise<IOrderEngineDefinitionModel> {
		const orderEngineDefinition: IOrderEngineDefinitionModel = await this.httpService.post("/orderEngine/updateOrderEngineDefinition", model);
		const idx = OrderEngineStore._orderEngineDefinitions.findIndex(x => x.uuid === orderEngineDefinition.uuid);
		OrderEngineStore._orderEngineDefinitions[idx] = orderEngineDefinition;

		return orderEngineDefinition;
	}

	async deleteOrderEngineDefinition(uuid: string): Promise<void> {
		await this.httpService.delete(`/orderEngine/deleteOrderEngineDefinition?uuid=${uuid}`);
		OrderEngineStore._orderEngineDefinitions = OrderEngineStore._orderEngineDefinitions.filter(x => x.uuid !== uuid);
	}

}
